import { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { BlueTickMark } from 'icons'

import { WseUserId } from 'api/types'
import { ActivityData, CueCardItemArray, ParticipantData, SelectedDataModal } from './type'

import { BreakoutRoomsState } from 'pages/useBreakouts'
import { ConferenceContext } from 'contexts/conference'

import { numberToAlphabet } from '../breakout-rooms/breakout.service'

import { CueCardBRModal } from './CueCardBR'
import { CueCardSelect } from './CueCardSelect'
import { ViewCueCardImage } from '../ViewCueCardsModal'
import { ActivityFilterSelectTextField, ActivityFilterMenuItem, SelectActivityContainer, SelecteActivityTitle, Tick } from './style'
import { Divider } from '@mui/material'
import { pushEventOnDataLayer } from 'services/gaDataLayer'
import { BookingContext } from 'contexts/booking'

export const sortingArray = (studentData: ParticipantData[]): ParticipantData[] => {
    const sortedStudentData = studentData.sort((a: { firstName: string }, b: { firstName: string }) =>
        a.firstName.localeCompare(b.firstName, 'en', { sensitivity: 'base' }))
    const uniqueStudentData = sortedStudentData.filter(
        (student, index, sortedStudentArray) => index === sortedStudentArray.findIndex(sortedStudent => sortedStudent.id === student.id)
    )
    return uniqueStudentData
}

const CueCardsModal: React.FC<{
    close: () => void
    setTriggerDefault: () => void
    setAllCueCardSelected: React.Dispatch<React.SetStateAction<boolean>>
    triggerFunction: string
    withCC?: boolean
    breakouts?: BreakoutRoomsState
    borDuration?: number
    setZoomedCueCardImage?: React.Dispatch<React.SetStateAction<ViewCueCardImage>>
    usersRooms?: Array<{ userId: WseUserId; roomId: number; indexId: number }>
    timer?: Date | null
}> = ({
    close,
    breakouts,
    triggerFunction,
    timer,
    setTriggerDefault,
    setAllCueCardSelected,
    setZoomedCueCardImage,
    borDuration,
    usersRooms,
    withCC = false
}) => {
    const navigate = useNavigate()
    const conference = useContext(ConferenceContext)
    const booking = useContext(BookingContext)

    const cueCardResponse = conference.status === 'session-joined' && conference.cueCardInfo

    const cueCardForStudent = conference.status === 'session-joined' && conference.cueCard

    // function to get the student list without teacher
    const studentList = (): ParticipantData[] => sortingArray(
        conference.participants.filter(p => p.role !== 'teacher').map(data => ({
            id: data.wseUserId,
            firstName: data.firstName,
            lastName: data.lastName
        }))
    )

    const [studentNameList, setStudentNameList] = useState<ParticipantData[]>(studentList())

    const [selectOpen, setSelectOpen] = useState<boolean>(false)
    const [selectedCueCards, setSelectedCueCards] = useState<CueCardItemArray>([])
    const [selectedActivity, setSelectedActivity] = useState<string>('All Activity')

    // Function to handle the data based on selection and unselection
    const menuItemHandlerNew = (selectedStatus: boolean, participant: ParticipantData, selectedValues?: SelectedDataModal | null): void => {
        if (!cueCardForStudent) return

        if (cueCardForStudent) {
            const selectedCueCardsArray = selectedCueCards.length > 0 ? selectedCueCards : cueCardForStudent

            const updatedStudents = selectedCueCardsArray.map(student => {
                if (student.id === participant.id) {
                    if (selectedStatus && selectedValues) {
                        const mediaIndex = selectedValues[1]
                        return {
                            ...student,
                            cueCard: {
                                contentId: selectedValues[0].activityMedia[mediaIndex].contentId,
                                imageURL: selectedValues[0].activityMedia[mediaIndex].path
                            },
                            activityNumber: selectedValues[0].activityNumber,
                            indexValue: numberToAlphabet(mediaIndex + 1)
                        }
                    } else {
                        return {
                            id: participant.id,
                            cueCard: null
                        }
                    }
                }

                return student
            })

            const updateStudentSelection = studentNameList.map(user =>
                user.id === participant.id ? { ...user, isSelected: !!selectedStatus } : user
            )

            setStudentNameList(updateStudentSelection)
            setSelectedCueCards(updatedStudents)
        }

    }

    // Function called while selecting the dropdown option
    const handleChange = (selectedValues: SelectedDataModal, participant: ParticipantData): void => {
        menuItemHandlerNew(true, participant, selectedValues)
    }

    // Function called while clicking clear all selection button at bottom
    const clearAllCueCard = (): void => {
        setStudentNameList(studentList())
        const newSelectedCueCardArray = selectedCueCards.map(data => {
            return {
                id: data.id,
                cueCard: null
            }
        })
        setSelectedCueCards(newSelectedCueCardArray)
    }

    const triggerGAForCCInBR = (includeUsage: boolean): void => {
        const teacherId = conference.participants.find(participant => participant.role === 'teacher')?.wseUserId || ''
        if (booking.status === 'validated' && conference.status === 'session-joined' && teacherId) {
            const baseEventData = {
                user_id: teacherId,
                class_type: conference.classroomStateOnJoin.classTypeName,
                class_id: booking.classroomId
            }

            includeUsage && pushEventOnDataLayer('goc_cue_cards_in_breakout_rooms', baseEventData)

            if (timer) {
                const duration = ((new Date().getTime() - timer.getTime()) / 1000).toString()
                const timeSpentEventData = {
                    ...baseEventData,
                    duration,
                    using_suggestion: `${withCC}`
                }

                pushEventOnDataLayer('goc_cue_cards_in_breakout_rooms_time_spent', timeSpentEventData)
            }
        }
    }

    // Function called while share cue cards button at bottom
    const onShareCueCardsClick = (): void => {
        if (withCC) {
            usersRooms && breakouts?.setRoomActive({ duration: Number(borDuration), usersRooms })
            triggerGAForCCInBR(true)
        }
        navigate('/teacher/classroom/cue-cards')
        close()

        if (breakouts?.isActive) {
            triggerGAForCCInBR(false)
        }

        if (conference.status !== 'session-joined' || selectedCueCards.length === 0) return

        // If the student left the session and teacher shares the cue card for others, then if the left out student enters again
        // he should not see the cue card
        const updatedCueCards = selectedCueCards.map(cueCard => {
            const matchingStudent = studentNameList.find(student => student.id === cueCard.id)
            return matchingStudent ? {
                ...cueCard,
                cueCard: cueCard.id === matchingStudent.id ? cueCard.cueCard : null
            } : {
                id: cueCard.id,
                cueCard: null
            }
        })

        conference.setCueCardForStudent(updatedCueCards)
        conference.primus.send('cueCard:add', { cueCards: updatedCueCards })
    }

    useEffect(() => {
        if (triggerFunction.length) {
            switch (triggerFunction) {
                case 'share':
                    onShareCueCardsClick()
                    setTriggerDefault()
                    break
                case 'clear':
                    clearAllCueCard()
                    setTriggerDefault()
                    break
                default:
                    setTriggerDefault()
                    break
            }
        }
    }, [triggerFunction])

    // useEffect to update the student list when new student added or removed
    useEffect(() => {
        const updatedStudentList = studentList()

        if (updatedStudentList.length > studentNameList.length) {
            updatedStudentList.forEach((updatedstudent: ParticipantData) => {
                const filteredStudentNameList = studentNameList.find(student => student.id === updatedstudent.id)
                if (!filteredStudentNameList) {
                    studentNameList.push({
                        id: updatedstudent.id,
                        firstName: updatedstudent.firstName,
                        lastName: updatedstudent.lastName
                    })
                }
            })

        } else {
            studentNameList.forEach((student, index) => {
                const filteredUpdatedStudentList = updatedStudentList.find((updatedstudent: { id: string }) => updatedstudent.id === student.id)
                if (!filteredUpdatedStudentList) {
                    studentNameList.splice(index, 1)
                }
            })

        }

        setStudentNameList(sortingArray(studentNameList))
    }, [conference.participants])

    // useEffect for make the share cue card button enable if any one selection done
    useEffect(() => {
        const selectedStudentNameList = studentNameList.filter(data => data.isSelected)
        setAllCueCardSelected(studentNameList.length === selectedStudentNameList.length)
    }, [studentNameList])

    useEffect(() => {

        const isCueCardAvailable = cueCardForStudent ? cueCardForStudent.find(data => data.cueCard) : false

        if (isCueCardAvailable && cueCardForStudent) {

            // once after refresh set the activity number and index from the cue card saved data
            const updatedCueCardForStudent = cueCardForStudent.map(student => {
                let matchingActivity: ActivityData | undefined

                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                cueCardResponse ? cueCardResponse.forEach(activity => {
                    if (student.cueCard?.contentId) {
                        const filteredActivity = activity.activityMedia.find((media: { contentId: string }) => media.contentId === student.cueCard?.contentId)
                        if (filteredActivity) {
                            matchingActivity = {
                                activityNumber: activity.activityNumber,
                                index: activity.activityMedia.indexOf(filteredActivity)
                            }
                        }
                    }
                }) : null

                if (matchingActivity) {
                    return {
                        ...student,
                        activityNumber: matchingActivity.activityNumber,
                        mediaIndex: matchingActivity.index,
                        indexValue: numberToAlphabet(matchingActivity.index + 1)
                    }
                } else {
                    return student
                }
            })

            setSelectedCueCards(updatedCueCardForStudent)
        }

        if (isCueCardAvailable && cueCardForStudent) {

            const cueCardUsers: ParticipantData[] = []

            cueCardForStudent.forEach(data => {

                const filters = studentNameList.find(user =>
                    user.id === data.id
                )

                if (!filters) return

                cueCardUsers.push(
                    { ...filters, isSelected: !!data.cueCard }
                )
            })

            setStudentNameList(cueCardUsers)
        }
    }, [])

    const CueCardComponent = useMemo(() => {
        return (withCC || breakouts?.isActive) ? CueCardBRModal : CueCardSelect
    }, [withCC, breakouts?.isActive])

    return (
        <>
            <SelectActivityContainer isBreakout={withCC || breakouts?.isActive}>
                <SelecteActivityTitle>
                    <h2>
                        Select Activity
                    </h2>
                    <p>
                        Select the class activity
                    </p>
                </SelecteActivityTitle>
                <div>
                    <ActivityFilterSelectTextField
                        defaultValue={selectedActivity}
                        label=''
                        margin='none'
                        maxRows={4}
                        minRows={1}
                        name='textfield'
                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            setSelectedActivity(event.target.value)
                            setSelectOpen(false)
                        }}
                        value={selectedActivity}
                        onClick={() => setSelectOpen(true)}
                        placeholder='Select a Cue Card'
                        rows={1}
                        select
                        size='medium'
                        type='text'
                        variant='outlined'
                        SelectProps={{
                            IconComponent: ExpandMoreIcon,
                            MenuProps: {
                                PaperProps: {
                                    sx: theme => ({
                                        borderRadius: '0px 0px 8px 8px',
                                        maxHeight: '192px',
                                        width: '200px',
                                        border: '1px solid rgba(0, 0, 0, 0.14)',
                                        boxShadow: '0px 7px 20px 0px rgba(0, 0, 0, 0.06), 0px 3px 1px 0px rgba(0, 0, 0, 0.02)',
                                        padding: '0px 8px 8px 8px',
                                        '& .MuiMenu-list': {
                                            paddingTop: theme.spacing(2)
                                        },
                                        '.MuiMenuItem-root': {
                                            backgroundColor: 'transparent !important',
                                            padding: '6px 8px',
                                            '&:hover': {
                                                backgroundColor: '#71809629',
                                                cursor: 'pointer'
                                            }
                                        }
                                    })
                                }
                            }
                        }}
                    >
                        <ActivityFilterMenuItem isSelected={selectedActivity === 'All Activity'} id='category' key={'activity-all'} value={'All Activity'}>
                            <p>
                                All Activity
                            </p>
                            {selectOpen && selectedActivity === 'All Activity' ? <Tick src={BlueTickMark} alt='Tick' /> : null}
                        </ActivityFilterMenuItem>
                        {cueCardResponse ? cueCardResponse.map((activities, index) => (
                            <ActivityFilterMenuItem
                                isSelected={selectedActivity === `Activity ${activities.activityNumber}`}
                                id='category'
                                key={`activity-${activities.activityNumber}`}
                                value={`Activity ${activities.activityNumber}`}
                            >
                                <p>Activity {activities.activityNumber}</p>
                                {selectOpen && selectedActivity === `Activity ${activities.activityNumber}` ? <Tick src={BlueTickMark} alt='Tick' /> : null}
                            </ActivityFilterMenuItem>
                        )) : 'null'}
                    </ActivityFilterSelectTextField>
                </div>
            </SelectActivityContainer>
            {!(withCC || breakouts?.isActive) ? <Divider sx= {{ marginBottom: '16px', backgroundColor: '1px solid grey' }} /> : null}
            <CueCardComponent
                breakouts={breakouts as BreakoutRoomsState}
                studentNameList={studentNameList}
                selectedActivity={selectedActivity}
                handleChange={handleChange}
                selectedCueCards={selectedCueCards}
                cueCardResponse={cueCardResponse}
                breakoutActive={breakouts?.isActive || false}
                setZoomedCueCardImage={setZoomedCueCardImage}
                index={0}
            />
        </>
    )
}

export { CueCardsModal }
