import { RefObject, useContext, useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material'
import { SlideDeckNavigationController } from 'ispring'
import { ConferenceContext } from 'contexts/conference'
import { ISpringContext, useThumbnails } from 'contexts/ispring'
import { Presentation } from './presentation/Presentation'
import { getQuery, SnbStage } from 'contexts/ispring/get-query'
import { SlideDeckInfo } from 'api/cms/fetch-ppts'
import { getNormalizeString } from 'utils/get-normalizeString'

const SlidesWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    position: 'relative',
    flexWrap: 'nowrap',
    width: '100%',
    height: '70%'
}))
const SlidesCarousel = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: theme.spacing(25),
    flexShrink: 0,
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    overflow: 'auto',
    background: theme.palette.neutral.darker
}))
const SlideItem = styled('img')(({ theme }) => ({
    width: '100%',
    flex: 'none',
    marginBottom: theme.spacing(1.5),
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
    cursor: 'pointer',
    '&:last-of-type': {
        marginBottom: 0
    },
    '> iframe': {
        border: 'none',
        position: 'relative',
        top: '-4px',
        left: '-5px',
        width: `calc(100% + ${theme.spacing(1.2)})`,
        height: `calc(100% + ${theme.spacing(1)})`
    }
}))
const SkeletonItem = styled('div')(({ theme }) => ({
    width: theme.spacing(25),
    height: theme.spacing(14),
    flex: 'none',
    marginBottom: theme.spacing(1.5),
    '&:last-of-type': {
        marginBottom: 0
    }
}))
const SkeletonPresentation = styled('div')(({ theme }) => ({
    flex: 'auto',
    height: '100%',
    marginLeft: theme.spacing(2),
    borderRadius: theme.spacing(2),
    background: theme.palette.neutral.darker
}))

const SlidesPresentation: React.FC = () => {
    const iSpring = useContext(ISpringContext)
    const thumbnails = useThumbnails()
    const conference = useContext(ConferenceContext)
    const [navigationController, setNavigationController] = useState<SlideDeckNavigationController>()
    const images = useRef<Array<RefObject<HTMLImageElement>>>([])

    useEffect(() => {
        if (iSpring.status === 'player-ready' && conference.status === 'session-joined') {
            images.current[iSpring.storedActiveSlide.index]?.current?.scrollIntoView(true)
            conference.primus.send('wb:ppt-action', {
                stepIndex: iSpring.storedActiveSlide.step,
                slide: iSpring.storedActiveSlide.index,
                board: 0
            })

            navigationController?.gotoTimestamp(
                iSpring.storedActiveSlide.index,
                iSpring.storedActiveSlide.step
            )
        }
    }, [navigationController, conference.status])

    useEffect(() => {
        if (conference.status === 'session-joined') {
            conference.primus.send('change-screen', { screen: 'slides' })
        }
    }, [conference.status])

    useEffect(() => {
        images.current = thumbnails?.map(item => item.ref) ?? []
    }, [thumbnails])

    useEffect(() => {
        if (iSpring.status === 'ready' && conference.status === 'session-joined') {
            const classTopic = conference.classroomStateOnJoin.Scd
            const isTeens = sessionStorage.getItem('isTeensClass')
            let selectedDeck: SlideDeckInfo
            const [classType] = getQuery(
                conference.classroomStateOnJoin.Levels as SnbStage[],
                conference.classroomStateOnJoin.activityType,
                conference.classroomStateOnJoin.unit
            )
            if (classType === 'online_social_club' || classType === 'online_complementary_class') {
                const normalizedClassTopic = getNormalizeString(classTopic)
                const filteredListBasedOnClassTopic = iSpring.decks.filter(deck => getNormalizeString(deck.title).includes(normalizedClassTopic))
                const finalList = filteredListBasedOnClassTopic.length === 0 ? iSpring.decks : filteredListBasedOnClassTopic
                const hasGOCPPT = finalList.some(deck => deck.title.includes('[Global Online]'))
                const index = finalList.findIndex(deck => {
                    const teensPPT = deck.title.includes('[Teens]')
                    const gocPPT = deck.title.includes('[Global Online]')
                    const nonTeensPPT = (hasGOCPPT ? gocPPT : (!teensPPT && !gocPPT))

                    return ((isTeens === 'true') ? teensPPT : nonTeensPPT)
                })
                selectedDeck = (index !== -1) ? finalList[index] : iSpring.decks[0]
            } else {
                const hasGOCPPT = iSpring.decks.some(deck => deck.title.includes('[Global Online]'))
                const index = iSpring.decks.findIndex(deck => {
                    const teensPPT = deck.title.includes('[Teens]')
                    const gocPPT = deck.title.includes('[Global Online]')
                    const nonTeensPPT = hasGOCPPT ? gocPPT : (!teensPPT && !gocPPT)

                    return (isTeens === 'true') ? teensPPT : nonTeensPPT
                })
                selectedDeck = (index !== -1) ? iSpring.decks[index] : iSpring.decks[0]
            }

            iSpring.setPresentationUrl(selectedDeck.sameOriginUrl)

            if (!conference.classroomStateOnJoin.dcAppData.wbData.boards[0].mount) {
                conference.primus.send('wb:import-file', {
                    name: selectedDeck.title,
                    label: selectedDeck.title,
                    layout: '16:9',
                    type: 'ISPRING',
                    fileType: 'ppt',
                    board: 0,
                    url: selectedDeck.sameOriginUrl
                })
            }
        }

        if (iSpring.status === 'player-ready' && conference.status === 'session-joined') {
            const navController = new SlideDeckNavigationController(iSpring.player)
            setNavigationController(navController)

            const stepEventHandler = (slideIndex: number, stepIndex: number): void => {
                conference.primus.send('wb:ppt-action', { board: 0, slide: slideIndex, stepIndex })
                images.current[slideIndex].current?.scrollIntoView(true)
            }
            const removeStepEventHandler = navController.addStepEventHandler(stepEventHandler)

            return () => {
                removeStepEventHandler()
                iSpring.close(navController.activeSlide)
            }
        }
    }, [iSpring.status])

    return (
        <SlidesWrapper id='teacher-slides'>
            <SlidesCarousel id='teacher-slides-carousel'>
                {iSpring.status === 'player-ready' && thumbnails ? (
                    thumbnails.map((thumbnail, i) => (
                        <SlideItem
                            ref={thumbnail.ref}
                            key={i}
                            srcSet={thumbnail.url}
                            onClick={() => navigationController?.gotoTimestamp(i, 0)}
                            data-testid='slide-item'
                        />
                    ))
                ) : (
                    <>{[...Array(8)].map((_, i) => <SkeletonItem key={i} data-testid='skeleton-item' />)}</>
                )}
            </SlidesCarousel>
            {iSpring.status === 'presentation-selected' || iSpring.status === 'player-ready' ? (
                <Presentation
                    presentationUrl={iSpring.presentationUrl}
                    navigationController={navigationController}
                />
            ) : <SkeletonPresentation data-testid='skeleton-presentation' />}
        </SlidesWrapper>
    )
}

export { SlidesPresentation }
