import { useContext, useEffect, useState } from 'react'

import { Action } from 'hello-design-system'

import { BreakoutRoomsState } from 'pages/useBreakouts'
import { ConferenceContext } from 'contexts/conference'

import { ButtonTexts, DialogContent } from 'utils/constants'
import { fetchButtonData } from '../breakout-rooms/breakout.service'

import { CueCardsModal } from './CueCardsModal'
import { ShareCCDialogComponent } from '../dialog'
import { ViewCueCardImage } from '../ViewCueCardsModal'

const { ShareCC, ApplyChanges, ClearSelection } = ButtonTexts

const ShareCueCardsModal: React.FC<{
    active: boolean
    withCC?: boolean
    breakouts?: BreakoutRoomsState
    close: () => void
    setZoomedCueCardImage?: React.Dispatch<React.SetStateAction<ViewCueCardImage>>
}> = ({ active, close, breakouts, setZoomedCueCardImage, withCC = false }) => {
    const conference = useContext(ConferenceContext)

    const cueCardForStudent = conference.status === 'session-joined' && conference.cueCard

    const [triggerFunction, setTriggerFunction] = useState<string>('')
    const [isAllCueCardSelected, setAllCueCardSelected] = useState<boolean>(true)
    const [actions, setAction] = useState<Action[]>([])
    const [startTimer, setStartTimer] = useState<Date | null>(null)

    useEffect(() => {
        const isCueCardSaved = (cueCardForStudent ? cueCardForStudent.filter(data => data.cueCard) : []).length
        setAction([
            fetchButtonData(ClearSelection, 'outlined', () => setTriggerFunction('clear')),
            fetchButtonData(isCueCardSaved ? ApplyChanges : ShareCC, 'contained', isAllCueCardSelected ? () => setTriggerFunction('share') : () => null)
        ])
    }, [cueCardForStudent])

    useEffect(() => {
        if (breakouts?.isActive) {
            setStartTimer(new Date())
        }
        return () => {
            setStartTimer(null)
        }
    }, [])

    const renderCueCardDialog = (): JSX.Element => (
        <ShareCCDialogComponent
            open={active}
            onClose={close}
            title={DialogContent.CueCards.title}
            actions={actions}
            isAllCueCardSelected={isAllCueCardSelected}
            maxWidth={'sm'}
            showCloseIcon={true}
            fullScreen={false}
        >
            <CueCardsModal
                close={close}
                breakouts={breakouts}
                withCC={withCC}
                timer={startTimer}
                triggerFunction={triggerFunction}
                setTriggerDefault={() => setTriggerFunction('')}
                setZoomedCueCardImage={setZoomedCueCardImage}
                setAllCueCardSelected={setAllCueCardSelected}
            />
        </ShareCCDialogComponent>
    )

    return (
        renderCueCardDialog()
    )
}

export { ShareCueCardsModal }
