import { useContext, useState, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { Action } from 'hello-design-system'

import { ConferenceContext } from 'contexts/conference'
import { BreakoutRoomsState } from 'pages/useBreakouts'

import { ButtonTexts, DialogContent } from 'utils/constants'
import { fetchButtonData, getMode, studentRoomMapping } from './breakout.service'

import { RoomsModalStep } from './RoomsModalStep'
import { BreakoutRoomDialogComponent } from '../dialog'
import { CueCardsModal } from '../cue-cards/CueCardsModal'
import { ViewCueCardImage } from '../ViewCueCardsModal'

export type StudentRoomMappingType = Map<string, RoomAssignment>

export type RoomAssignment = {
    wseProfilePhotoUrl?: string
    firstName: string
    lastName: string
    roomId?: number
    id: string
    indexId: number
}

const { ClearSelection, Next, Previous, ShareCC, StartBR, SaveChanges, ApplyChanges } = ButtonTexts

const { BreakoutRoom } = DialogContent

const BreakoutRoomsModal: React.FC<{
    active: boolean
    withCC: boolean
    breakouts: BreakoutRoomsState
    close: () => void
    setZoomedCueCardImage?: React.Dispatch<React.SetStateAction<ViewCueCardImage>>
}> = ({ active, breakouts, close, setZoomedCueCardImage, withCC = false }) => {
    const conference = useContext(ConferenceContext)
    const { pathname } = useLocation()

    const studentCount = conference.participants.filter(participant => participant.role !== 'teacher').length

    const [borDuration] = useState<number>(5)
    const [borMode] = useState<'speak' | 'cue-cards' | 'slides' | 'whiteboard'>(getMode(pathname))
    const [step, setStep] = useState<number>(1)
    const [triggerFunction, setTriggerFunction] = useState<string>('')
    const [roomA, setRoomA] = useState<RoomAssignment[]>([])
    const [roomB, setRoomB] = useState<RoomAssignment[]>([])
    const [roomC, setRoomC] = useState<RoomAssignment[]>([])
    const [roomD, setRoomD] = useState<RoomAssignment[]>([])
    const [roomsEmpty, setRoomsEmpty] = useState<boolean>(false)
    const [roomsFull, setRoomsFull] = useState<boolean>(false)
    const [roomsCount, setRoomCount] = useState<number>(1)
    const [startTimer, setStartTimer] = useState<Date | null>(null)
    const [isAllCueCardSelected, setAllCueCardSelected] = useState<boolean>(true)

    useEffect(() => {
        if (breakouts.isActive) {
            const students: RoomAssignment[] = Array.from(studentRoomMapping(conference.participants.filter(p => p.role !== 'teacher')).values())
            breakouts.rooms.forEach(room => {
                const roomStu = students.filter(stu => room.users.includes(stu.id))
                setRooms(room.id, roomStu)
            })
        }
    }, [])

    useEffect(() => {
        if (withCC) {
            setStartTimer(new Date())
            return () => {
                setStartTimer(null)
            }
        }
    }, [withCC])

    const handleBorStart = (): void => {
        close()

        const usersRooms = [roomA, roomB, roomC, roomD].flatMap((room, roomId) =>
            room.map(s => ({ userId: s.id, roomId, indexId: s.indexId }))
        )

        breakouts.start({
            mode: borMode,
            duration: borDuration,
            usersRooms
        })
    }

    const getRoom = (index: number): RoomAssignment[] => {
        const rooms = [roomA, roomB, roomC, roomD]
        return rooms[index] || []
    }

    const setRooms = (index: number, students: RoomAssignment[]): void => {
        const setRoomFunctions = [setRoomA, setRoomB, setRoomC, setRoomD]
        if (setRoomFunctions[index]) {
            // add the students to the dropped room
            setRoomFunctions[index](prev => students.length ? students.map((s, indexId) => ({ ...s, roomId: index, indexId })) : prev)

            // remove the students from the previous room
            const studentIds = students.map(s => s.id)
            setRoomFunctions.map((setRoom, roomIndex) => roomIndex !== index && setRoom(prev => prev.filter(s => !studentIds.includes(s.id))))
        }
    }

    const moveStep = (): void => {
        if (checkRoomLimit()) return
        const usersRooms = [roomA, roomB, roomC, roomD].flatMap((room, roomId) =>
            room.map(s => ({ userId: s.id, roomId, indexId: s.indexId }))
        )

        breakouts.setRoomData({
            mode: withCC ? 'cue-cards' : borMode,
            duration: borDuration,
            usersRooms
        })
        setStep(2)
    }

    useEffect(() => {
        setRoomCount(studentCount <= 3 ? 1 : studentCount <= 5 ? 2 : studentCount <= 7 ? 3 : 4)
    }, [studentCount])

    useEffect(() => {
        if (!breakouts.isActive) {
            const students = studentRoomMapping(conference.participants.filter(p => p.role !== 'teacher'))

            const assignments: RoomAssignment[] = Array.from(students.values())
            const initialAssignments: RoomAssignment[] = [...assignments]
            let studentsPushed = 0
            let chunkSizeDecreased = false

            Array.from(Array(4)).forEach((_, i) => {
                const chunkSize = Math.ceil(assignments.length / roomsCount)
                let studentsToPush = chunkSize
                if (!chunkSizeDecreased && studentsPushed > chunkSize) {
                    chunkSizeDecreased = true
                    if (initialAssignments.length - studentsPushed > (roomsCount - (i + 1)) * (i + 1)) {
                        studentsToPush = chunkSize + 1
                    }
                }
                studentsToPush += roomsCount === i + 1 ? 1 : 0
                studentsPushed += studentsToPush
                setRooms(i, assignments.splice(0, studentsToPush))
            })
        }
    }, [conference.participants, roomsCount])

    useEffect(() => {
        setRoomsEmpty(!roomA.length || !roomB.length || (roomsCount > 2 && !roomC.length) || (roomsCount > 3 && !roomD.length))
        setRoomsFull(roomA.length > 4 || roomB.length > 4 || (roomsCount > 2 && roomC.length > 4) || (roomsCount > 3 && roomD.length > 4))
    }, [roomA, roomB, roomC, roomD, roomsCount])

    const renderCCModal = (): JSX.Element => (
        <CueCardsModal
            withCC={withCC}
            timer={startTimer}
            breakouts={breakouts}
            close={close}
            triggerFunction={triggerFunction}
            borDuration={borDuration}
            setTriggerDefault={() => setTriggerFunction('')}
            setZoomedCueCardImage={setZoomedCueCardImage}
            setAllCueCardSelected={setAllCueCardSelected}
            usersRooms={[roomA, roomB, roomC, roomD].flatMap((room, roomId) =>
                room.map(s => ({ userId: s.id, roomId, indexId: s.indexId }))
            )}
        />
    )

    const renderBRRoom = (): JSX.Element => (
        <RoomsModalStep
            roomsCount={4}
            getRoom={getRoom}
            setRooms={setRooms}
            roomsEmpty={roomsEmpty}
            roomsFull={roomsFull}
        />
    )

    const renderBRModal = useMemo(() => {
        return (withCC && step === 2) ? renderCCModal : renderBRRoom
    }, [withCC, step, roomA, roomB, roomC, roomD, triggerFunction])

    const modalHeading = withCC && step === 2 ? BreakoutRoom.stepTwoTitle : BreakoutRoom.stepOneTitle

    const step1Actions: Action[] = [
        fetchButtonData(
            active ? SaveChanges : withCC ? Next : StartBR,
            'contained',
            withCC ? moveStep : handleBorStart,
            withCC ? 'ArrowForwardRounded' : undefined
        )
    ]

    const step2Actions: Action[] = [
        fetchButtonData(Previous, 'outlined', () => setStep(1), 'ArrowBackRounded', 'start'),
        fetchButtonData(ClearSelection, 'outlined', () => setTriggerFunction('clear')),
        fetchButtonData(active ? ApplyChanges : ShareCC, 'contained', isAllCueCardSelected ? () => setTriggerFunction('share') : () => null)
    ]

    const checkRoomLimit = (): boolean => (roomA.length > 4 || roomB.length > 4 || roomC.length > 4 || roomD.length > 4)

    return (
        <BreakoutRoomDialogComponent
            open
            onClose={close}
            title={modalHeading}
            actions={step === 1 ? step1Actions : step2Actions}
            isAllCueCardSelected={step === 1 ? !checkRoomLimit() : isAllCueCardSelected}
            maxWidth={'sm'}
            showCloseIcon
        >
            {renderBRModal()}
        </BreakoutRoomDialogComponent>
    )
}

export { BreakoutRoomsModal }
