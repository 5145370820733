import { SlideDeckInfo } from 'api/cms/fetch-ppts'
import { ConferenceContextState } from 'contexts/conference'
import { getQuery, SnbStage } from 'contexts/ispring/get-query'
import { getNormalizeString } from './get-normalizeString'

export const getCurrentSlideInfo = (conference: ConferenceContextState, responseData: SlideDeckInfo[]): SlideDeckInfo | undefined => {

    if (conference.status === 'session-joined') {
        const classTopic = conference.classroomStateOnJoin.Scd
        let selectedDeck: SlideDeckInfo
        const isTeens = sessionStorage.getItem('isTeensClass')
        const [classType] = getQuery(
            conference.classroomStateOnJoin.Levels as SnbStage[],
            conference.classroomStateOnJoin.activityType,
            conference.classroomStateOnJoin.unit
        )
        if (classType === 'online_social_club' || classType === 'online_complementary_class') {
            const normalizedClassTopic = getNormalizeString(classTopic)
            const filteredListBasedOnClassTopic = responseData.filter((deck: { title: string | any }) =>
                getNormalizeString(deck.title).includes(normalizedClassTopic))
            const finalList = filteredListBasedOnClassTopic.length === 0 ? responseData : filteredListBasedOnClassTopic
            const hasGOCPPT = finalList.some((deck: { title: string | any }) => deck.title.includes('[Global Online]'))

            const index = finalList.findIndex((deck: { title: string | any }) => {
                const teensPPT = deck.title.includes('[Teens]')
                const gocPPT = deck.title.includes('[Global Online]')
                const nonTeensPPT = (hasGOCPPT ? gocPPT : (!teensPPT && !gocPPT))

                return ((isTeens === 'true') ? teensPPT : nonTeensPPT)
            })
            selectedDeck = (index !== -1) ? finalList[index] : responseData[0]

        } else {
            const hasGOCPPT = responseData.some((deck: { title: string | any }) => deck.title.includes('[Global Online]'))
            const index = responseData.findIndex((deck: { title: string | any }) => {
                const teensPPT = deck.title.includes('[Teens]')
                const gocPPT = deck.title.includes('[Global Online]')
                const nonTeensPPT = hasGOCPPT ? gocPPT : (!teensPPT && !gocPPT)

                return (isTeens === 'true') ? teensPPT : nonTeensPPT
            })
            selectedDeck = (index !== -1) ? responseData[index] : responseData[0]
        }

        return selectedDeck

    }

}
