import { styled } from '@mui/system'
import { ButtonWrapper, RectangleButton, StandardButton } from 'components/buttons'
import { MicLightIconButton } from 'components/icon-buttons/MicLightIconButton'
import { VideoLightIconButton } from 'components/icon-buttons/VideoLightIconButton'
import { ArrowIcon, MicMuteLightIcon, SpeakerIndicatorStaticIcon, BlueAlertIcon, SettingsEquimentIcon } from 'icons'
import { LogoContainer } from 'pages/logoContainer/LogoContainer'
import { ASPECT_RATIO } from 'zoom/config'
import { useContext, useEffect, useState, useRef } from 'react'
import { BookingContext } from 'contexts/booking'
import { getDisplayName, getSameOriginPhotoUrl } from 'utils/get-display-name'
import { MessageDialog } from 'components/dialog/MessageDialog'
import { IOSPermissionMessageDialog } from 'components/dialog/ios-permission-messageDialog'
import ZoomVideo, { MediaDevice } from '@zoom/videosdk'
import { useNavigate } from 'react-router-dom'
import { SessionWrapper } from 'components/wrappers'
import { useMediaDevices } from './useMediaDevices'
import { useTimer } from 'components/timer/useTimer'
import { Indicator } from 'components/timer/Indicator'
import { useNavigateBackDisabled } from 'pages/useNavigateBackDisabled'
import { useFeatureCheck } from 'hooks/useFeatureCheck'
import { SettingDialog } from 'pages/Diagnostics/SettingDialog'
import { rollbarService, RollbarExceptionNames } from 'services/rollbar'

const localAudioTrack = ZoomVideo.createLocalAudioTrack()

const InnerWrapper = styled('div')(({ theme }) => ({
    textAlign: 'center',
    width: '100%',
    position: 'relative',
    zIndex: 1
}))
const TileWrapper = styled('div')(({ theme }) => ({
    backgroundColor: 'rgba(241, 241, 241)',
    borderRadius: '20px',
    width: '100%',
    maxWidth: theme.spacing(67.75),
    aspectRatio: `${ASPECT_RATIO}`,
    margin: '0 auto'
}))
const textStyle = styled('div')(({ theme }) => ({
    lineHeight: '150%',
    letterSpacing: '0.02em',
    marginTop: theme.spacing(1.5)
}))
const MessageTitleWrapper = styled(textStyle)(({ theme }) => ({
    fontWeight: 800,
    fontSize: theme.spacing(3),
    color: theme.palette.primary.darker
}))
const MessageRemainingTimeWrapper = styled(textStyle)(({ theme }) => ({
    fontWeight: 600,
    fontSize: theme.spacing(2.5),
    color: '#000000'
}))
const MessageWrapper = styled(textStyle)(({ theme }) => ({
    marginTop: theme.spacing(1.5)
}))
const IconsContainer = styled('div')(({ theme }) => ({
    marginLeft: '16px'
}))
const ButtonContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    maxWidth: theme.spacing(67.75),
    margin: '16px auto 0 auto',
    alignItems: 'center',
    justifyContent: 'space-between'
}))
const Tile = styled('div')<{ showVideo: boolean }>(({ theme, showVideo }) => ({
    display: 'flex',
    alignItems: 'flex-end',
    position: 'relative',
    justifyContent: 'center',
    backgroundColor: showVideo ? 'transparent' : theme.palette.neutral.darker,
    borderRadius: theme.spacing(2.5),
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    willChange: 'background-color',
    transition: 'background-color 0.2s ease-out'
}))
const Avatar = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: theme.spacing(3),
    fontWeight: 800,
    height: '132px',
    maxHeight: theme.spacing(20),
    aspectRatio: '1',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.35)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: theme.palette.neutral.main
}))
const AudioControls = styled('div')(({ theme }) => ({
    width: '100%',
    height: theme.spacing(5),
    margin: theme.spacing(2),
    boxSizing: 'border-box',
    display: 'flex',
    position: 'absolute'
}))
const ExtraLargeIcon = styled('img')(({ theme }) => ({
    height: theme.spacing(5),
    width: theme.spacing(5),
    marginLeft: '16px'
}))
const ParticipantInitials = styled('span')(({ theme }) => ({
    fontSize: '36px',
    lineHeight: '106%'
}))
const FooterWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    padding: theme.spacing(2),
    background: 'rgba(100, 207, 233, 0.2)',
    position: 'absolute',
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center'
}))
const FooterMessage = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.65)',
    img: {
        paddingRight: '10px'
    },
    'span a': {
        textDecoration: 'underline',
        textDecorationSkipInk: 'none',
        textUnderlineOffset: '3px'
    }
}))

type DiagnosticsPageProperties = {
    startTime?: number | undefined
}

const DiagnosticsPage: React.FC<DiagnosticsPageProperties> = ({ startTime }) => {
    const { devices: mediaDevices, stream } = useMediaDevices()
    const booking = useContext(BookingContext)
    const { isMobileSafari } = useFeatureCheck()
    useNavigateBackDisabled()
    const videoRef = useRef<HTMLVideoElement | null>(null)
    const streamRef = useRef<MediaStream | null>(null)
    const [isMessageDialogOpen, setIsMessageDialogOpen] = useState(false)
    const [isMicActive, setIsMicActive] = useState(false)
    const [isCamActive, setIsCamActive] = useState(false)
    const [permissionType, setPermissionType] = useState<'microphone' | 'camera'>('microphone')
    const [videoDevices, setVideoDevices] = useState<MediaDevice[]>([])
    const [audioDevices, setAudioDevices] = useState<MediaDevice[]>([])
    const [isMicAlertEnabled, setIsMicAlertEnabled] = useState(false)
    const [isCamAlertEnabled, setIsCamAlertEnabled] = useState(false)
    const navigate = useNavigate()
    const isAudioAlreadyStarted = useRef(false)
    const callbackUrl = sessionStorage.getItem('callbackUrl')
    // when callback url is from Speak+(D2C) application then will navigate to its privacy policy page
    // otherwise it will navigate to core course privacy policy page
    const privacyPolicyUrl = callbackUrl?.includes('speak') ? `${callbackUrl}/privacy` : `${callbackUrl}/privacy-policy`
    const waitingTime = booking.status === 'validated' && booking.check.session.waitTime / 60
    const [remainingTime] = useTimer(startTime, +waitingTime)
    const [isSettingActive, setSettingsActive] = useState<boolean>(false)
    const [selectedCameraId, setSelectedCameraId] = useState<string>('')
    const disableSettings = process.env.REACT_APP_ENV !== 'production'

    useEffect(() => {
        // Clean up the stream when the component unmounts
        return () => {
            // On component unmount, stopping the audio & video
            isAudioAlreadyStarted.current && void localAudioTrack.stop()
            stopPreviewVideoButton()
        }
    }, [])

    // This effect runs whenever the selected camera changes
    const updateCameraStream = async (): Promise<void> => {
        // Stop the current stream if it exists
        if (streamRef.current) {
            streamRef.current.getTracks().forEach(track => track.stop())
        }
        const newStream = await navigator.mediaDevices.getUserMedia({
            video: { deviceId: selectedCameraId },
            audio: false
        })
        if (videoRef.current) {
            videoRef.current.srcObject = newStream
            streamRef.current = newStream
        }
    }
    useEffect(() => {
        if (selectedCameraId) {
            void updateCameraStream()
        }
    }, [selectedCameraId])

    const toggleMessageDialog = (): void => {
        setIsMessageDialogOpen(prevState => !prevState)
    }

    const onMicClickHandler = (): void => {
        if (audioDevices.length && !isMicAlertEnabled) {
            isMicActive ? void stopPreviewMicrophoneButton() : void startPreviewMicrophoneButton()
            setIsMicActive(prevState => !prevState)
        } else {
            setIsMicAlertEnabled(true)
            setPermissionType('microphone')
            toggleMessageDialog()
        }
    }

    const onCamClickHandler = (): void => {
        if (videoDevices.length && !isCamAlertEnabled) {
            isCamActive ? stopPreviewVideoButton() : updateCameraStream().catch(error => console.log('Error fetching data:', error))
            setIsCamActive(prevState => !prevState)
        } else {
            setIsCamAlertEnabled(true)
            setPermissionType('camera')
            toggleMessageDialog()
        }
    }
    const navigateToClassroom = (isDiagnostic: boolean): void => {
        const status = {
            isDiagnosed: true,
            isVideoMuted: !isCamActive,
            isAudioMuted: !isMicActive,
            isVideoDisabled: videoDevices.length === 0 && isCamAlertEnabled,
            selectedCamera: selectedCameraId
        }
        sessionStorage.setItem('diagnoseStatus', JSON.stringify(status))
        if (booking.status === 'validated' && booking.user.role === 'teacher') {
            navigate('/teacher/classroom', { state: { isDiagnostic } })
        } else {
            navigate('/classroom', { state: { isDiagnostic } })
        }
    }

    const mediaDeviceFilter = (kind: string): MediaDeviceInfo[] => {
        return mediaDevices?.filter((device: MediaDeviceInfo) => device?.kind === kind && device?.deviceId !== '') || []
    }

    const joinClass = (): void => {

        // check is audio permission is enabled if not show dialog
        const audioFilteredData = mediaDeviceFilter('audioinput')
        logRollbar(RollbarExceptionNames.DiagnosticJoinButtonClick)

        if (isMicAlertEnabled && !audioFilteredData.length) {
            setPermissionType('microphone')
            !isMicActive
                ? toggleMessageDialog()
                : logRollbarOnError(RollbarExceptionNames.DiagnosticJoinMicError)
        } else {
            if (booking.status === 'validated') {
                // Ensure the camera stream is updated with the selected camera before navigating
                if (selectedCameraId) {
                    void updateCameraStream().then(() => {
                        navigateToClassroom(true)
                    }).catch(e => {
                        logRollbarOnError(RollbarExceptionNames.DiagnosticJoinBookingVideoStreamError, e)
                    })
                } else {
                    // If no camera is selected, proceed with the existing logic
                    showPreview()
                    logRollbar(RollbarExceptionNames.DiagnosticNoIssue)
                    navigateToClassroom(true)
                }
            } else {
                logRollbarOnError(RollbarExceptionNames.DiagnosticJoinBookingStatusError)
            }
        }
    }

    const logRollbarOnError = (rollbarName: RollbarExceptionNames, error?: any, optionalData?: object): void => {
        let filteredVideoDevices: MediaDeviceInfo[] = []
        let filteredAudioDevices: MediaDeviceInfo[] = []

        if (mediaDevices && Array.isArray(mediaDevices) && mediaDevices.length > 0) {
            filteredVideoDevices = mediaDeviceFilter('videoinput')
            filteredAudioDevices = mediaDeviceFilter('audioinput')
        }

        rollbarService.rollbar.error(
            rollbarName,
            {
                bookingStatus: booking.status,
                filteredVideoDevices,
                filteredAudioDevices,
                isMicActive,
                isMicAlertEnabled,
                isCamActive,
                isCamAlertEnabled,
                error,
                ...optionalData
            }
        )
    }

    const logRollbar = (rollbarName: RollbarExceptionNames): void => {
        rollbarService.rollbar.log(rollbarName, {
            userId: booking.status === 'validated' ? booking.user.id : '',
            selectedCamera: selectedCameraId,
            bookingStatus: booking.status
        })
    }

    useEffect(() => {
        if (mediaDevices) {
            showPreview()
        } else {
            startPreviewVideoButton().catch(error => console.log('Error fetching data-mediadevice else loop:', error))
            checkAndStartOrStopTheDevices(void 0)
        }
    }, [mediaDevices])

    const showPreview = (): void => {
        let videoDevicesList: MediaDevice[] = []
        let audioDevicesList: MediaDevice[] = []

        videoDevicesList = mediaDeviceFilter('videoinput')
        audioDevicesList = mediaDeviceFilter('audioinput')

        setVideoDevices(videoDevicesList)
        setAudioDevices(audioDevicesList)

        if (videoDevicesList.length) {
            setSelectedCameraId(videoDevicesList[0].deviceId)
        }

        startPreviewVideoButton().then(value => {
            checkAndStartOrStopTheDevices(void 0)
        }).catch(err => {
            logRollbarOnError(
                RollbarExceptionNames.DiagnosticMediaDeviceNavigatorError,
                err,
                { err, errMessage: err.message, videoDevicesList, audioDevicesList }
            )
            console.log(err, err.message, videoDevicesList, audioDevicesList, 'Error - navigator.mediaDevices.getUserMedia()')
            checkAndStartOrStopTheDevices(void 0, err.message)
        })
    }
    const handleDeviceChange = (): void => {
        const filteredVideoDevices = mediaDeviceFilter('videoinput')
        const isSelectedCameraAvailable = filteredVideoDevices.some((device: MediaDeviceInfo) => device.deviceId === selectedCameraId)

        if (!isSelectedCameraAvailable && filteredVideoDevices.length) {
            setIsCamActive(true)
            setSelectedCameraId(filteredVideoDevices[0].deviceId)
            void updateCameraStream()
        } else if (!filteredVideoDevices.length) {
            setIsCamActive(false)
        }
        handleCameraSelection(filteredVideoDevices[0].deviceId)
    }
    navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange)

    const checkAndStartOrStopTheDevices = (isPermissionDenied: boolean = false, errMessage: string = ''): void => {
        const videoDevices = mediaDeviceFilter('videoinput')
        const audioDevices = mediaDeviceFilter('audioinput')
        if (errMessage === 'Permission denied by system') {
            isPermissionDenied && stopPreviewMicrophoneButton()
            isPermissionDenied && stopPreviewVideoButton()
            setIsCamActive(false)
            setIsMicActive(false)
            setIsMicAlertEnabled(!audioDevices.length)
            setIsCamAlertEnabled(!videoDevices.length)
        } else {
            // for audio devices
            if (audioDevices.length) {
                if (!isAudioAlreadyStarted.current) {
                    void localAudioTrack.start().then(() => {
                        isAudioAlreadyStarted.current = true
                        startPreviewMicrophoneButton()
                        setIsMicActive(true)
                    })
                } else {
                    startPreviewMicrophoneButton()
                    setIsMicActive(true)
                }
                setIsMicAlertEnabled(false)
            } else {
                isPermissionDenied && stopPreviewMicrophoneButton()
                setIsMicActive(false)
                setIsMicAlertEnabled(true)
            }
            // for video devices
            if (videoDevices.length) {
                startPreviewVideoButton().catch(error => {
                    console.log('the camera is not streaming in diagnostic:', error)
                    logRollbarOnError(RollbarExceptionNames.DiagnosticCameraNotStreaming, error)
                })
                setIsCamActive(true)
                setIsCamAlertEnabled(false)
            } else {
                isPermissionDenied && stopPreviewVideoButton()
                setIsCamActive(false)
                setIsCamAlertEnabled(true)
            }
        }
    }

    const startPreviewVideoButton = async (): Promise<void> => {
        try {
            let newStream = stream
            if (!stream?.active) {
                newStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true })
            }
            if (videoRef.current) {
                videoRef.current.srcObject = newStream
                streamRef.current = newStream
            }
        } catch (error) {
            console.log('Error - navigator.mediaDevices.getUserMedia()-startPreviewVideoButton()', error)
        }
    }

    const stopPreviewVideoButton = (): void => {
        if (streamRef.current && videoRef.current) {
            const tracks = streamRef.current.getTracks()
            tracks.forEach(track => track.stop())
            videoRef.current.srcObject = null
            streamRef.current = null
            // clear all the tracks in the stream
            for (const track of [...stream.getAudioTracks(), ...stream.getVideoTracks()]) {
                track.stop()
            }
        }
    }

    const startPreviewMicrophoneButton = (): void => {
        void localAudioTrack.unmute()
    }

    const stopPreviewMicrophoneButton = (): void => {
        void localAudioTrack.mute()
    }
    const toggleSettingsModal = (): void => {
        setSettingsActive(prevState => !prevState)
    }

    const handleCameraSelection = (CameraId: string): void => {
        setSelectedCameraId(CameraId)
    }

    return (
        <SessionWrapper>
            <LogoContainer>
                <InnerWrapper>
                    {booking.status === 'validated' ? (
                        <>
                            <TileWrapper>
                                <Tile showVideo={isCamActive}>
                                    <video
                                        id='preview-camera-video'
                                        ref={videoRef}
                                        style={{
                                            display: isCamActive ? 'block' : 'none',
                                            maxWidth: '100%'
                                        }}
                                        autoPlay
                                        playsInline
                                        muted>
                                    </video>

                                    {!isCamActive
                                        ? <Avatar id='participant-avatar'
                                            sx={{ backgroundImage: booking.user.photoUri ? `url('${getSameOriginPhotoUrl(booking.user.photoUri)}')` : 'none' }}>
                                            {!booking.user.photoUri ? (
                                                <ParticipantInitials id='participant-initials'>
                                                    {getDisplayName(booking.user, 'initials')}
                                                </ParticipantInitials>
                                            ) : null}
                                        </Avatar> : null}

                                    {remainingTime === 0 || booking.user.role === 'teacher' ? (
                                        <AudioControls>
                                            {isMicActive ? <ExtraLargeIcon src={SpeakerIndicatorStaticIcon} /> : <ExtraLargeIcon src={MicMuteLightIcon} />}
                                        </AudioControls>) : null
                                    }
                                </Tile>
                            </TileWrapper>
                            {remainingTime !== void 0 && remainingTime > 0 && booking.user.role === 'student' ? (
                                <MessageWrapper>
                                    <MessageTitleWrapper>Getting Ready</MessageTitleWrapper>
                                    <MessageRemainingTimeWrapper>
                                        Your class will start in
                                        {remainingTime !== void 0 &&
                                        <Indicator seconds={remainingTime} isForDiagnosticPage={true} />}{remainingTime <= 60 ? 'seconds' : 'minutes'}
                                    </MessageRemainingTimeWrapper>
                                </MessageWrapper>
                            ) : null }

                            {((remainingTime === 0 && booking.user.role === 'student') || booking.user.role === 'teacher') ? (
                                <ButtonContainer>
                                    <ButtonWrapper>
                                        <MicLightIconButton id='mic-button' active={isMicActive} alert={isMicAlertEnabled} onClick={onMicClickHandler} />
                                        <IconsContainer>
                                            <VideoLightIconButton
                                                id='video-button'
                                                active={isCamActive}
                                                alert={isCamAlertEnabled}
                                                onClick={onCamClickHandler}
                                            />
                                        </IconsContainer>
                                        {disableSettings && (
                                            <IconsContainer>
                                                <StandardButton
                                                    data-testid='setting-icon-equipmentPage'
                                                    id='setting-icon-equipmentPage'
                                                    color='secondary'
                                                    onClick={toggleSettingsModal}
                                                    iconSrc={SettingsEquimentIcon}
                                                />
                                            </IconsContainer>)}
                                    </ButtonWrapper>
                                    <RectangleButton
                                        id='join-class-button'
                                        text='Join Class'
                                        color='error'
                                        onClick={joinClass}
                                        iconSrc={ArrowIcon}
                                    />
                                </ButtonContainer>
                            ) : null}
                        </>
                    ) : null}
                </InnerWrapper>
                { isMobileSafari
                    ? <IOSPermissionMessageDialog type={permissionType} isOpen={isMessageDialogOpen} toggleIOSPermissionMessageDialog={toggleMessageDialog} />
                    : <MessageDialog type={permissionType} isOpen={isMessageDialogOpen} toggleMessageDialog={toggleMessageDialog} />
                }
                {isSettingActive && <SettingDialog close={toggleSettingsModal} onCameraSelect={handleCameraSelection} selectedCam={selectedCameraId} />}
            </LogoContainer>
            <FooterWrapper>
                <FooterMessage>
                    <img src={BlueAlertIcon} />
                    <span>This activity may be recorded for training purposes,
                    quality control and for your and our staff’s protection, as described in our&nbsp;
                    <a href={privacyPolicyUrl} target='_blank' rel='noreferrer noopener'>privacy policy</a>
                    </span>
                </FooterMessage>
            </FooterWrapper>
        </SessionWrapper>
    )
}

export { DiagnosticsPage }
